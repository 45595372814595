<template>
  <div>
    <Navbar page="Gestão de Requisições" link="/requisicao" nameLink="Gestão de Requisições" />
    <div class="mx-1 md:mx-5 my-8">
      <div v-if="requisicao" class="px-3 pb-4">
            <h5 @click="detalhe = !detalhe" class="font-bold text-base bg-yellow-400 rounded py-2 px-2 my-2 relative cursor-pointer uppercase" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                1. Detalhes da requisição
                <svg v-if="!detalhe" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="detalhe" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>
            <div v-show="detalhe" class="py-2">
                <p class="text-base font-bold text-justify my-1">PROTOCOLO: <span class="font-bold">{{ requisicao.protocolo ? requisicao.protocolo : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Direito requisitado: <span class="font-normal">{{ requisicao.direito ? requisicao.direito.direito : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Data da requisição: <span class="font-normal">{{ requisicao.data | moment("DD/MM/YYYY HH:mm") }}</span></p>
                <p class="text-base font-bold text-justify my-2">Origem da requisição: <span class="font-normal">{{ requisicao.origem ? requisicao.origem : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Primeiro e último nome do requisitante: <span class="font-normal">{{ requisicao.nome ? requisicao.nome : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">3 últimos digitos do CPF: <span class="font-normal">{{ requisicao.cpf_cnpj ? requisicao.cpf_cnpj : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Email: <span class="font-normal">{{ requisicao.email ? requisicao.email : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Telefone: <span class="font-normal">{{ requisicao.telefone ? requisicao.telefone : '' }}</span></p>
                                  <p class="text-base font-bold text-justify my-2">Perfil como titular dos dados: <span class="font-normal">{{ requisicao.tipoTitular && requisicao.tipoTitular.tipo ? requisicao.tipoTitular.tipo : '' }}</span></p>
                <p class="text-base font-bold text-justify my-2">Observações do titular: <span class="font-normal">{{ requisicao.descricao ? requisicao.descricao : '' }}</span></p>
            </div>

            <h5 @click="localizar = !localizar" class="font-bold text-base bg-yellow-400 rounded py-2 px-2 my-2 relative cursor-pointer uppercase" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                2. Localizar titular da requisição
                <svg v-if="!localizar" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="localizar" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>
            <div v-show="localizar" class="py-2">
                <div v-if="requisicao.titular">
                  <p class="text-base font-bold text-justify my-1">Data de início de relacionamento: <span v-if="requisicao.titular && requisicao.titular.data_relacionamento" class="font-normal">{{ requisicao.titular.data_relacionamento | moment("DD/MM/YYYY") }}</span></p>
                  <p class="text-base font-bold text-justify my-1">Primeiro e último nome: <span class="font-normal">{{ requisicao.nome ? requisicao.nome : '' }}</span></p>
                  <label for="titularmenor" class="text-base font-medium">
                      <input type="checkbox" v-model="requisicao.identidadeConfirmada" class="rounded-sm" id="titularmenor">
                      <span class="ml-2">
                        Identidade do titular confirmada
                      </span>
                  </label>
                  <textarea rows="3" v-model="requisicao.comoIdentificouIdentidade" placeholder="Como identificou a titularidade?" type="text" name="comoIdentificouIdentidade" id="comoIdentificouIdentidade" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-96 shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                 
                  <p class="text-base font-bold text-justify my-2">Tipo de relacionamento: <span class="font-normal">{{ requisicao.titular && requisicao.titular.tipoTitular ? requisicao.titular.tipoTitular.tipo : '' }}</span></p>
                  <p class="text-base font-bold text-justify my-2">Tipo de Dados tratados do titular: 
                    <span class="font-normal">
                      <span v-if="requisicao.titular && requisicao.titular.dadostratados.length">
                        <span v-for="(dado, idx) in requisicao.titular.dadostratados" :key="dado._id">
                          {{dado.nome}}<span v-if="idx < requisicao.titular.dadostratados.length - 1">, </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  <p class="text-base font-bold text-justify my-2">Setores que tratam os dados do titular:
                    <span class="font-normal">
                      <span v-if="setores && setores.length">
                        <span v-for="(setor, idx) in setores" :key="setor">
                          {{setor}}<span v-if="idx < setores.length - 1">, </span>
                        </span>
                      </span>
                    </span>
                  </p>
                  
                  <div class="flex flex-col my-3">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Atividade
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Finalidade
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Dados
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Base legal
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="item in atividades" :key="item._id">
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.nome ? item.nome : ''}}
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.finalidade && item.finalidade.length ? item.finalidade.map(f => f.titulo).join(",") : ''}}
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      <span v-for="dado in item.dados" :key="dado._id">{{ dado.nome }}, </span>
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.baselegal && item.baselegal.length ? item.baselegal.map(f => f.nome).join(",") : ''}}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="flex flex-col my-3">
                    <p class="text-base font-bold text-justify my-2">Os dados são compartilhados com:</p>
                  
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                          <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                              <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Empresa
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  Como
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                  País
                                </th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="item in empresas" :key="item.empresa">
                                <td class="px-6 py-4 whitespace-nowrap">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.empresa ? item.empresa : ''}}
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.como ? item.como : ''}}
                                    </div>
                                  </div>
                                </td>

                                <td class="px-6 py-4">
                                  <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                      {{item.pais ? item.pais : 'Brasil'}}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </div>

                <div v-if="!requisicao.titular">
                  <div class="bg-red-500 text-white p-4 w-96 text-center shadow-lg mb-2">Titular não localizado.</div>
                  <label for="titularmenor" class="block text-base font-medium">Selecionar titular manualmente:</label>
                  <select v-model="titularUpdate" class="mt-2 mb-2 w-96 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="t in titulares" :key="t._id" :value="t._id">{{t.nome}}</option>
                  </select>
                  <button
                    @click="updateTitular"
                    type="button"
                    class="text-white hover:bg-blue-700 bg-blue-500 rounded py-1 px-4 mx-1">
                    Atualizar
                  </button>
                </div>
            </div>

            <h5 @click="analisar = !analisar" class="font-bold text-base bg-yellow-400 rounded py-2 px-2 my-2 relative cursor-pointer uppercase" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                3. Analisar requisição
                <svg v-if="!analisar" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="analisar" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>

            <div v-show="analisar" class="py-2">
                <p class="text-base font-bold text-justify my-1">Detalhes da requisição: <span class="font-normal">{{ requisicao.direito ? requisicao.direito.detalhes : '' }}</span></p>
                <div class="text-base font-bold text-justify my-2">Ações necessárias: 
                  
                  <div v-if="requisicao.titular && requisicao.direito">
                    <div class="bg-green-500 text-white p-4 text-center shadow-lg mb-2 mt-2" v-for="(acao, idx) in requisicao.direito.acoesPositivo" :key="`ap${idx}`">Hipótese {{idx+1}}: {{acao}}</div>
                  </div>
                  
                  <div v-if="!requisicao.titular && requisicao.direito">
                    <div class="bg-red-500 text-white p-4 text-center shadow-lg mb-2 mt-2" v-for="(acao, idx) in requisicao.direito.acoesNegativo" :key="`an${idx}`">Hipótese {{idx+1}}: {{acao}}</div>
                  </div>

                </div>
            </div>

            <h5 @click="responder = !responder" class="font-bold text-base bg-yellow-400 rounded py-2 px-2 my-2 relative cursor-pointer uppercase" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                4. Responder requisição
                <svg v-if="!responder" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
                <svg v-if="responder" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 absolute right-4 top-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                </svg>
            </h5>

            <div v-show="responder" class="py-2">
                <select @change="replaceVars"  name="opcaoPositiva" id="opcaoPositiva" v-if="requisicao.titular && requisicao.direito" class="w-full mb-4" v-model="emailSelecionado">
                  <option v-for="email in requisicao.direito.emailsPositivo" :value="email" :key="email"><span class="w-96 truncate" v-html="truncate(email, 50)"></span>...</option>
                </select>

                <select @change="replaceVars" name="opcaoNegativa" id="opcaoNegativa" v-if="!requisicao.titular && requisicao.direito" class="w-full mb-4" v-model="emailSelecionado">
                  <option v-for="email in requisicao.direito.emailsNegativo" :value="email" :key="email"><span class="w-96 truncate" v-html="truncate(email, 50)"></span>...</option>
                </select>

                <VueEditor v-model="emailSelecionado" name="emailSelecionado" id="emailSelecionado"></VueEditor>

                <div class="grid grid-cols-12 gap-6 bg-gray-100 px-4 py-2">
                  <div class="col-span-12 md:col-span-4">
                      Anexar documentos:
                      <vue-dropzone ref="anexos" id="anexos" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="updateAnexos">
                      </vue-dropzone>
                  </div>
                  <div class="col-span-12 md:col-span-8">
                      <table class="min-w-full divide-y divide-gray-200 mt-6">
                          <thead class="bg-gray-50">
                              <tr>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                      ID
                                  </th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                      Arquivo
                                  </th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                      Opções
                                  </th>
                              </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="(anexo, index) in anexos" :key="index">
                                  <td class="px-6 py-4 whitespace-nowrap">
                                      <div class="flex items-center">
                                          <div class="text-sm text-gray-500">
                                              {{ index }}
                                          </div>
                                      </div>
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap">
                                      <div class="flex items-center">
                                          <div class="text-sm text-gray-500">
                                              <a target="_blank" :href="`${url_api}/upload?mimetype=${anexo.mimetype}&filename=${anexo.filename}&folder=${anexo.folder ? anexo.folder : 'empresas'}&original=${anexo.originalname}`">{{ anexo.originalname }}</a>
                                          </div>
                                      </div>
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap">
                                      <div class="flex items-center">
                                          <div class="text-sm text-gray-500">
                                              <button @click="anexos.splice(index, 1)" type="button" class="text-white hover:bg-red-500 bg-red-400 rounded-lg py-2 px-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                              </button>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                </div>

                <div class="mt-4">
                  <button
                    @click="enviarResposta"
                    type="button"
                    class="text-white hover:bg-yellow-700 bg-yellow-500 rounded py-1 px-4 mx-1"
                    :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
                  >
                    Enviar email de resposta
                  </button>
                  <button
                    @click="save('Pendente')"
                    type="button"
                    class="text-white hover:bg-blue-700 bg-blue-500 rounded py-1 px-4 mx-1"
                  >
                    Marcar como pendente
                  </button>
                  <button
                    @click="save('Respondido')"
                    type="button"
                    class="text-white hover:bg-green-700 bg-green-500 rounded py-1 px-4 mx-1"
                  >
                    Marcar como respondido
                  </button>
                </div>
                
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
      vueDropzone
  },
  data() {
    return {
      route: "requisicao",
      requisicao: null,
      dpo: null,
      detalhe: true, 
      localizar: true,
      analisar: true, 
      responder: true,
      setores: [],
      baseslegais: [],
      atividades: [],
      empresas: [],
      anexos: [],
      titulares: [],
      titularUpdate: null,
      emailSelecionado: '',
      dropzoneOptions: this.$http.getDropzoneConfig(
        'v1/upload',
        'post',
        {
            maxFiles: 1,
            addRemoveLinks: true,
            capture: true,
            dictDefaultMessage: 'Clique ou arraste sua evidência aqui',
            dictRemoveFile: 'Remover'
        }
      ),
    };
  },
  methods: {
    async start(){
      const id = this.$route.params.id;

      if (id) {
        const req = await this.$http.get(`/v1/${this.route}/${id}`);
        this.dpo = req.data.dpo;
        this.requisicao = req.data.data;
        this.setores = req.data.setores;
        this.baseslegais = req.data.baseslegais;
        this.atividades = req.data.atividades;
        this.empresas = req.data.empresas;
        this.anexos = req.data.data.anexos;

        if (this.requisicao.emailSelecionado) this.emailSelecionado = this.requisicao.emailSelecionado;
      }
    },
    replaceVars() {
      this.emailSelecionado = this.emailSelecionado.replace('##NOME##', this.requisicao.nome);
      this.emailSelecionado = this.emailSelecionado.replace('##EMPRESA##', this.requisicao.empresa.nome);
      this.emailSelecionado = this.emailSelecionado.replace('##DPO##', this.dpo ? this.dpo.nome : 'DPO');
      this.emailSelecionado = this.emailSelecionado.replace('##DPOEMAIL##', this.dpo ? this.dpo.email : 'DPOEMAIL');
    },
    send(file, xhr, formData) {
      formData.append('folder', 'empresas');
    },
    updateAnexos(file, response) {
        this.anexos.push(response.file);
        this.$refs.anexos.removeAllFiles();
    },
    async save(status) {
      this.requisicao.status = status;
      const req = await this.$http.put(`/v1/${this.route}`, this.requisicao);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/${this.route}` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    },
    async updateTitular() {
      if(!this.titularUpdate) return this.$vToastify.error("Selecione o titular");
      const req = await this.$http.put(`/v1/${this.route}`,{ _id: this.$route.params.id, titular: this.titularUpdate });
      if (req.data.success) {
        this.$vToastify.success("Atualizado com sucesso!");
        this.start();
      } else {
        this.$vToastify.error(req.data.err);
      }
    }, 
    async enviarResposta() {

       this.$confirm({
          title: 'Confirmação',
          message: `Confirma o envio da resposta?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (!confirm) return;

            const req = await this.$http.post(`/v1/${this.route}/responder`, { requisicao: this.requisicao, emailSelecionado: this.emailSelecionado, anexos: this.anexos });

            if (req.data.success) {
              this.$vToastify.success("Respondido com sucesso!");
            } else {
              this.$vToastify.error(req.data.err);
            }
          }
      });
    },
    truncate(data, num) {
        const reqdString = data.split("").slice(0, num).join("");
        return reqdString;
    }
  },
  async beforeMount() {
    await this.start();
    const titularesReq = await this.$http.post(`/v1/titulares/list`, { all: true });
    this.titulares = titularesReq.data.data;
  },
};
</script>
